import { useContext } from 'react';
import { InterviewSheetContext } from '../contexts/InterviewSheetContext';
import { QuestionContext } from '../contexts/QuestionContext';
import { QuestionItemContext } from '../contexts/QuestionItemContext';
import { ConditionContext } from '../contexts/ConditionContext';
import { PrerequisiteContext } from '../contexts/PrerequisiteContext';
import {
  PostInterviewSheet,
  PutInterviewSheet,
} from '../types/interviewSheetApiTypes';
import { AnswerType } from '../types/webInterview';
import { defaultInterviewSheet } from '../reducers/interviewSheetReducer';
import { dateConvert } from '../utils/dateConvert';

export const useInterviewSheet: (
  id: string,
) => PostInterviewSheet | PutInterviewSheet = (id: string) => {
  const { state: interviewSheetState } = useContext(InterviewSheetContext);
  const { state: questionState } = useContext(QuestionContext);
  const { state: questionItemState } = useContext(QuestionItemContext);
  const { state: conditionState } = useContext(ConditionContext);
  const { state: prerequisiteState } = useContext(PrerequisiteContext);

  const {
    name,
    description,
    releaseDate,
    conditionPid,
    conditionZipCode,
    conditionStreetAddress,
    conditionMailAddress,
    displayPriority,
    version,
    isNew,
  } =
    interviewSheetState.find((x) => x.interviewSheetId === id) ??
    defaultInterviewSheet;

  const questionItems = questionItemState.map((qi) => ({
    id: qi.questionItemId,
    choice_text: qi.choiceText,
    has_choice_notes: qi.hasChoiceNotes,
    choice_notes_prefix: qi.choiceNotesPrefix,
    choice_notes_suffix: qi.choiceNotesSuffix,
    choice_notes_max_length: 20,
    choice_placeholder: qi.choicePlaceholder,
    description_max_lines: 0,
    description_max_length: 0,
    description_notes_prefix: qi.descriptionPrefix,
    description_notes_suffix: qi.descriptionSuffix,
    description_placeholder: qi.descriptionPlaceholder,
    sort_number: qi.sortNumber,
    tags: qi.tags,
    question_id: qi.questionId,
  }));

  const prerequisiteItems = prerequisiteState.map((p) => ({
    id: p.prerequisiteId,
    question_item_id: p.questionItemId,
    condition_id: p.conditionId,
    question_id: p.questionId,
  }));

  const conditions = conditionState.map((c) => ({
    id: c.conditionId,
    age_required: c.ageRequired,
    age: c.age,
    age_operator: String(c.ageOperator),
    sex_required: c.sexRequired,
    male: c.sex === 'male' ? true : false,
    female: c.sex === 'female' ? true : false,
    question_required: c.questionRequired,
    prerequisite_question_id: c.prerequisiteQuestionId,
    prerequisite_items: prerequisiteItems
      .filter((p) => p.condition_id === c.conditionId)
      .map(({ condition_id, ...others }) => others),
    question_id: c.questionId,
  }));

  const questions = questionState
    .sort((a, b) => a.sortNumber - b.sortNumber)
    .map((q) => {
      const filteredQuestionItems = questionItems.filter(
        (qi) => qi.question_id === q.questionId,
      );
      const sortedQuestionItems = filteredQuestionItems
        .map((qi, idx) => ({
          ...qi,
          temp_sort_number: qi.sort_number * 100 + idx,
        }))
        .sort((a, b) => a.temp_sort_number - b.temp_sort_number)
        .map(({ temp_sort_number, ...others }, idx) => ({
          ...others,
          sort_number: idx + 1,
        }));

      return {
        id: q.questionId,
        type: String(q.answerType),
        text: q.text,
        required: q.required,
        sort_number: q.sortNumber,
        question_items: formatQuestionItem(
          sortedQuestionItems,
          q.questionId,
          q.answerType,
        ),
        condition: conditions.find((c) => c.question_id === q.questionId) ?? {},
      };
    });

  const result = {
    name: name,
    description: description,
    release_date: dateConvert(releaseDate, 'seconds', 'T', '+09:00'),
    condition_pid: conditionPid,
    condition_zip_code: conditionZipCode,
    condition_street_address: conditionStreetAddress,
    condition_mail_address: conditionMailAddress,
    questions: questions,
    display_priority: displayPriority,
    version: version,
  };

  if (isNew) {
    return { ...result, id };
  }
  return result;
};

const formatQuestionItem = (
  state: {
    id: string;
    choice_text: string | undefined;
    has_choice_notes: boolean;
    choice_notes_prefix: string | undefined;
    choice_notes_suffix: string | undefined;
    choice_notes_max_length: number;
    choice_placeholder: string;
    description_max_lines: number;
    description_max_length: number;
    description_notes_prefix: string | undefined;
    description_notes_suffix: string | undefined;
    description_placeholder: string;
    sort_number: number;
    tags: string[];
    question_id: string;
  }[],
  questionId: string,
  answerType: AnswerType,
) => {
  if (
    answerType === 'checkbox' ||
    answerType === 'combobox' ||
    answerType === 'radiobutton'
  ) {
    return state
      .filter((qi) => qi.question_id === questionId)
      .map((qi) => ({
        id: qi.id,
        choice_text: qi.choice_text,
        has_choice_notes: qi.has_choice_notes,
        choice_notes_prefix: qi.choice_notes_prefix,
        choice_notes_suffix: qi.choice_notes_suffix,
        choice_notes_max_length: 20,
        choice_placeholder: qi.choice_placeholder,
        description_max_lines: 0,
        description_max_length: 0,
        description_notes_prefix: '',
        description_notes_suffix: '',
        description_placeholder: '',
        sort_number: qi.sort_number,
        tags: qi.tags,
      }));
  } else if (answerType === 'textbox' || answerType === 'textline') {
    return state.length > 0
      ? [
          {
            id: state[0].id,
            choice_text: '',
            has_choice_notes: false,
            choice_notes_prefix: '',
            choice_notes_suffix: '',
            choice_notes_max_length: 20,
            choice_placeholder: '',
            description_max_lines: 0,
            description_max_length: 0,
            description_notes_prefix: state[0].description_notes_prefix,
            description_notes_suffix: state[0].description_notes_suffix,
            description_placeholder: state[0].description_placeholder,
            sort_number: 1,
            tags: state[0].tags,
          },
        ]
      : [
          {
            id: '',
            choice_text: '',
            has_choice_notes: false,
            choice_notes_prefix: '',
            choice_notes_suffix: '',
            choice_notes_max_length: 20,
            choice_placeholder: '',
            description_max_lines: 0,
            description_max_length: 0,
            description_notes_prefix: '',
            description_notes_suffix: '',
            description_placeholder: '',
            sort_number: 1,
            tags: [],
          },
        ];
  } else {
    return state.map((qi) => ({
      id: qi.id,
      choice_text: '',
      has_choice_notes: false,
      choice_notes_prefix: '',
      choice_notes_suffix: '',
      choice_notes_max_length: 20,
      choice_placeholder: '',
      description_max_lines: 0,
      description_max_length: 0,
      description_notes_prefix: '',
      description_notes_suffix: '',
      description_placeholder: '',
      sort_number: 1,
      tags: [],
    }));
  }
};
